.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100%;
  background-color: #282c34;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
}

.left-container {
  display: flex;
  align-content: flex-start;
}

.right-container {
  display: flex;
  align-items: flex-end;
}

.logo {
  font-weight: bold;
  font-size: 1.5em;
}

.icon {
  font-size: 1.5em;
  margin-left: 10px;
  cursor: pointer; /* Add cursor pointer */
}

.icon:hover {
  color: #007bff; /* Change color on hover */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.language-info {
  display: flex;
  border: 2px solid white;
  border-radius: 10px;
  height: 60vh; /* 90% of the viewport height */
  width: 90vw; /* 90% of the viewport width */
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: 0;
}

.left-section {
  flex: 1;
  border-right: 2px solid white;
  padding: 10px;
  max-width: 50%;
}

.right-section {
  flex: 2;
}

.section {
  margin-bottom: 10px;
  height: 33%;
  overflow-y: auto;
  overflow-x: auto;
}

.guess-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  align-items: center; /* Center items vertically */
}

.divider {
  border-top: 1px solid white;
  margin: 5px 0;
}

.label {
  font-weight: bold;
}

.big-area {
  padding: 5px;
  border-radius: 10px;
}

.guess {
  display: flex;
  border-radius: 10px;
  height: min-content;
  width: min-content;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 5px;
}

.guess-left-section {
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 3px;
}

.guess-right-section {
}

.guess-icon-container {
  border-radius: 50%; /* Round shape for the container */
}

.guess-content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 3px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.plusAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: green;
  animation: fadeOut 2s ease forwards;
}

.modal {
  background-color: #282c34;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  height: 70%;
  width: 70% ;
  overflow-y: auto;
}

.guessing-box {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 460px;
  height: 55px;
  border: 2px solid lightgray;
  border-radius: 7px;
  background: white;
  color: darkgray;
}

a {
  color: lightblue;
}

a:visited {
  color: magenta;
}

